#commandbar-recorder-mask {
  background: transparent;
}
#recorder {
  animation: 0.4s ease-out scaleIn;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.06);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
