#commandbar-proxy *,
#commandbar-proxy *::before,
#commandbar-proxy *::after {
  box-sizing: border-box;
}

#commandbar-proxy {
  margin: 0;
  font-family: Avenir, sans-serif;
}

/* RC Dialog */

.rc-dialog-content {
  background-color: transparent !important;
  background-clip: border-box !important;
  border: none !important;
  border-radius: 8px !important;
}
